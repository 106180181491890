import Icon from './Icon';

const CHEVRON_EXPAND = (
  <svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.8546 8.36644L11.9937 13.2273L7.13289 8.36644C6.6443 7.87785 5.85503 7.87785 5.36644 8.36644C4.87785 8.85503 4.87785 9.6443 5.36644 10.1329L11.1168 15.8832C11.6054 16.3718 12.3946 16.3718 12.8832 15.8832L18.6336 10.1329C19.1221 9.6443 19.1221 8.85503 18.6336 8.36644C18.145 7.89038 17.3432 7.87785 16.8546 8.36644V8.36644Z" />
  </svg>
);

export default function IcChevronExpand({className}) {
  return (
    <Icon className={className} label="ChevronExpand">
      {CHEVRON_EXPAND}
    </Icon>
  );
}

IcChevronExpand.propTypes = {
  className: Icon.propTypes.className,
};

IcChevronExpand.defaultProps = {
  className: '',
};
